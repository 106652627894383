
import React from "react";
import AppLink from './AppLink';

const UserNavigation = ({className}) => (
  <ul className={className} >
    <li className="nav-item">
      <AppLink to="/favorites" className="nav-link">
        <ion-icon name="star-outline"></ion-icon>
      </AppLink>
    </li>
    <li className="nav-item">
      <AppLink to="/my-account" className="nav-link">
        <ion-icon name="person"></ion-icon>
      </AppLink>
    </li>
  </ul>
);

export default UserNavigation
