import appConfig from '../appConfig';
import React, { Component} from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import YearNav from './YearNav';
import YearNavMobile from './YearNavMobile';
import EventCalendarFilters from './EventCalendarFilters';
import EventLogo from './EventLogo';
import appActions from '../redux/actions/appActions';

import ioc2iso from '../resources/ioc_to_iso2.json';
import iso2country from '../resources/iso2_to_country.json';
import utils from '../helpers/utils';
import Scheduled from './Scheduled';
// import ReactDOM from 'react-dom'


// import matchHeight from 'jquery-match-height';
// import Isotope from 'isotope-layout';
import $ from 'jquery';

// var isotope;
var countryFilters = [];
var monthFilters = [];
var typeFilters = [];
var genderFilters = [];

var componentHasAlreadyFetchedOneTime = false;
const mapStateToProps = state => {
  return {
    filersState: state.app.filters,
  };
};
const mapDispatchToProps = dispatch => ({
    saveFilters: (filtersState) => { dispatch(appActions.saveFilters(filtersState))},
    resetFilters: () => { dispatch(appActions.resetFilters())},
  });
class EventListing extends Component {
  constructor(props){
    super(props);
    // console.log(props, 'PROPS');
    // this.state = {
    //   countryFilterState: false,
    //   monthFilterState: false,
    //   typeFilterState: false,
    //   genderFilterState: false,
    // }

    this.articles = [];
  }

  // filters method
  addToGenderFilters(gender){
    if(gender && genderFilters.indexOf(gender) === -1){
      genderFilters.push(gender);
    }
  }
  addToCountryFilters(country){
    if(country && countryFilters.indexOf(country) === -1){
      countryFilters.push(country);
    }
  }
  addToMonthFilters(month){
    if(month && monthFilters.indexOf(month) === -1){
      monthFilters.push(month);
    }
  }
  addToTypeFilters(eventType){
    if(eventType && typeFilters.indexOf(eventType) === -1){
      typeFilters.push(eventType);
    }
  }
  // handleFiltersChange(e){
  //   var val = (e) ? e.value : false;
  //   this.setState({
  //     monthFilterState: val
  //   });
  // }
  handleResetAllFilters(){
    // this.setState({
    //     countryFilterState: false,
    //     monthFilterState: false,
    //     typeFilterState: false,
    //     genderFilterState: false,
    //   })
    this.props.resetFilters();
  }
  handleCountryChange(e){
    // var val = (e) ? e.value : false;
    // this.setState({
    //   countryFilterState: val
    // });
    this.props.saveFilters(Object.assign({}, this.props.filersState, {
      countryFilterState: e
    }));
  }
  handleMonthChange(e){
    // var val = (e) ? e.value : false;
    // this.setState({
    //   monthFilterState: val
    // });
    this.props.saveFilters(Object.assign({}, this.props.filersState, {
      monthFilterState: e
    }));
  }

  handleTypeChange(e){
    // var val = (e) ? e.value : false;
    // this.setState({
    //   typeFilterState: val
    // });
    this.props.saveFilters(Object.assign({}, this.props.filersState, {
      typeFilterState: e
    }));
  }
  handleGenderChange(e){
    // var val = (e) ? e.value : false;
    // this.setState({
    //   genderFilterState: val
    // });
    this.props.saveFilters(Object.assign({}, this.props.filersState, {
      genderFilterState: e
    }));
  }
  handleArticleClick(e, el){
    if(el.event_url){
      e.preventDefault();
      e.stopPropagation();
      const $target = $(e.target);
      const $link = $target.closest('article').find('h3 a');
      if($link.hasClass('hosted-by-provider')){
        window.location.href = $link.attr('href');
      } else {
        this.props.history.push($link.attr('href'));
      }
    }
  }

  getNodes(equalizerComponent, equalizerElement) {
    var nodes = [];
    for (var ref in this.refs) {
        nodes.push(this.refs[ref])
    }

    return nodes;
  }

  // componentDidUpdate(){
  //   var truncateEventTitlev2 = () => {
  //     console.log('truncate');
  //     console.log(this.articles);
  //     this.articles.forEach((article)=>{
  //       console.log('truncate inner');
  //       var el = $(article).find('h3')[0];
  //       console.log('truncate inner');
  //       console.log(el.scrollHeight);
  //       console.log(el.offsetHeight);
  //       if(el){
  //         var wordArray = el.innerHTML.split(' ');
  //
  //         while(el.scrollHeight > el.offsetHeight) {
  //             wordArray.pop();
  //             el.innerHTML = wordArray.join(' ') + '...';
  //
  //             console.log(el.scrollHeight);
  //             console.log(el.offsetHeight);
  //          }
  //       }
  //     })
  //   }
  //   setTimeout(truncateEventTitlev2, 500);
  //   // truncateEventTitlev2();
  //   $(document).resize(truncateEventTitlev2);
  // }

  componentDidUpdate(){
    var truncateEventTitlev2 = () => {

      for (var prop in this.articles) {
        if (this.articles.hasOwnProperty(prop) && this.articles[prop]) {
          var el = $(this.articles[prop]).find('h3')[0];

          if(el){
            var wordArray = el.innerHTML.split(' ');

            while(el.scrollHeight > el.offsetHeight) {
                wordArray.pop();
                el.innerHTML = wordArray.join(' ') + '...';
             }
          }
        }
      }
    }
    setTimeout(truncateEventTitlev2, 500);
    // truncateEventTitlev2();
    $(document).resize(truncateEventTitlev2);
  }

  getEventStatus(el){
    var status;
    var today = (appConfig.emulated_date) ? appConfig.emulated_date : (new Date()) ;
    const currentYear = (new Date()).getYear() + 1900;
    today = new Date(today.getMonth()+ 1 + '/' + today.getDate() + '/' +currentYear);

    if(!el.canceled && el.canceled !== 'true'){
      if((today <= el.end_date_obj) && (today >= el.start_date_obj)){
        status = 'CURRENT'
      } else if (today < el.start_date_obj) {
        status = 'UPCOMING'
      }
    }

    return status;
  }

  getCurrentYearSeason(){
    const { SportInfo } = this.props;
    const currentYear = (new Date()).getFullYear();

    let currentYearSeason;
    if(SportInfo.slug === 'alpine-skiing'){
      currentYearSeason = (new Date()).getMonth() < 9 ? currentYear : currentYear + 1;
    } else {
      currentYearSeason = currentYear;
    }
    return currentYearSeason;
  }

  buildEventArticle(events){
    var { SportInfo, match } = this.props;

    var sectionPath = match.url;
    // add current year if not present in the url
    if(!sectionPath.match(/([0-9]{4})/)){
      const year = match.params.year || this.getCurrentYearSeason();
      sectionPath += '/' + year ;
    }

    const createLink = (el) => {
      if(el.hosted_by_provider && el.event_url){
        return (
         <a className="hosted-by-provider" rel="noopener noreferrer" href={'https://gym.longinestiming.com/' + el.event_url} >
           {el.name}
         </a>
        )
      } else if(el.event_url) {
        return (
          <Link to={ sectionPath + '/' + el.id } >
           {el.name}
          </Link>
        )
      } else {
        return (
          <span>{el.name}</span>
        )
      }
    }

    // Definition of the event element here
    // this.articles = this.articles ? this.articles : [];
    var events_el = events.map((el, i) => {
      var flag;
      var isoCode = ioc2iso[el.ioc_code.toString().toLowerCase()];
      if(el.ioc_code){
        flag = 'fi-' + isoCode;
      }

      const eventCountry = iso2country[isoCode];
      const eventType = el.type.toString().toLowerCase();

      const eventMonthStart = utils.getMonthName(el.start_date_obj);
      const eventMonthEnd = utils.getMonthName(el.end_date_obj);
      const eventMonth = (eventMonthStart && eventMonthEnd && eventMonthStart === eventMonthEnd) ? eventMonthStart : eventMonthStart + ' ' + eventMonthEnd;

      this.addToCountryFilters(eventCountry);
      this.addToGenderFilters(el.gender);
      this.addToMonthFilters(eventMonthStart);
      this.addToMonthFilters(eventMonthEnd);
      this.addToTypeFilters(eventType);

      var eventCancelledClassName ='';
      var eventCancelledInfo;

      if(el.canceled === 'true'){

        eventCancelledClassName = 'event-cancelled';
        eventCancelledInfo = <div className="event-cancelled-info"><p>CANCELLED</p></div>;
      }

      var badge;
      var today = (appConfig.emulated_date) ? appConfig.emulated_date : (new Date()) ;
      const currentYear = (new Date()).getYear() + 1900;
      today = new Date(today.getMonth()+ 1 + '/' + today.getDate() + '/' +currentYear);

      if(!el.canceled && el.canceled !== 'true'){
        if((today <= el.end_date_obj) && (today >= el.start_date_obj)){
          badge = <div className="live-event-badge current-event">CURRENT</div>
        } else if (today < el.start_date_obj) {
          badge = <div className="live-event-badge upcoming-event">UPCOMING</div>;
        }
      }

      const eventDateStr = utils.eventDate(el);
      const startTime = (el.start_time) ? el.start_time + ' CET': '';

      const noUrlClassName = (!el.event_url) ? 'event-no-url' : '';

      return (
        <article id={'article-'+ el.id } ref={(article)=>{this.articles[el.id] = article}} key={el.id} onClick={(e)=>{this.handleArticleClick(e, el)}} className={ "event animated fadeIn col-lg-3 col-md-6 col-12 " +  eventCancelledClassName + " " + noUrlClassName}>
          <div className="event-inner">
          <div className="row">
            { badge }
            <header className="event-image col-sm-12 col-5">
             <div className="event-image-inner">
                <EventLogo event={el} SportInfo={SportInfo} />
                {eventCancelledInfo}
             </div>
            </header>
            <div className="event-content col-sm-12 col-7">
              <div className="event-content-inner">

              <h3>
                { createLink(el) }
              </h3>
              <ul className="event-details list-unstyled">
                <li>
                  <span className="icon" >
                    <ion-icon name="pin"></ion-icon>
                  </span>
                  <span className="city">{el.city}</span>, &nbsp;
                  <span className="ioc-code">{el.ioc_code.toUpperCase()}</span>
                </li>
                <li>
                  <span className="icon" >
                    <ion-icon name="time"></ion-icon>
                  </span>
                  { eventDateStr} {startTime}
                </li>
              </ul>
              <footer className="event-footer">
                <ul className="nav">
                  <li>
                    <a href="#">
                      <ion-icon name="share-alt"></ion-icon>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <ion-icon name="star-outline"></ion-icon>
                    </a>
                  </li>
                </ul>
              </footer>
              </div>
            </div>
            <div className="flag-overlay"><span className={"flag-icon " + flag }></span></div>
          </div>
         </div>
        </article>
      )
    });
    return events_el;
  }

  render() {
    // reset filters for each rendering
    countryFilters = [];
    monthFilters = [];
    typeFilters = [];
    genderFilters = [];

    var { SportInfo, match, events } = this.props;

    if(events.length > 0){
      componentHasAlreadyFetchedOneTime = true;
    }

    var sectionPath = match.url;
    // add current year if not present in the url
    if(!sectionPath.match(/([0-9]{4})/)){
      const year = match.params.year || (new Date()).getFullYear();
      sectionPath += '/' + year ;
    }

    //Apply filters to events
    const { countryFilterState, monthFilterState, typeFilterState, genderFilterState } = this.props.filersState;

    if(
        countryFilterState
    ){
      // country filtering
      let events_1 = events;
      if(genderFilterState){
        events_1 = events.filter((event)=>{
          return genderFilterState.value === event.gender
        })
      }

      // type filtering
      if(typeFilterState){
        events_1 = events.filter((event)=>{
          return typeFilterState.value === event.type.toString().toLowerCase()
        })
      }

      // month filtering
      if(monthFilterState){
        events_1 = events.filter((event)=>{
          return monthFilterState.value === event.month || monthFilterState.value === event.end_month
        })
      }
      events_1.map((el, i) => {
        var isoCode = ioc2iso[el.ioc_code.toString().toLowerCase()]
        const eventCountry = iso2country[isoCode];

        this.addToCountryFilters(eventCountry);
      })
    }
    if(
        monthFilterState
    ){
      // country filtering
      let events_2 = events;
      if(genderFilterState){
        events_2 = events.filter((event)=>{
          return genderFilterState.value === event.gender
        })
      }

      // type filtering
      if(typeFilterState){
        events_2 = events.filter((event)=>{
          return typeFilterState.value === event.type.toString().toLowerCase()
        })
      }

      // country filtering
      if(countryFilterState){
        events_2 = events.filter((event)=>{
          var isoCode = ioc2iso[event.ioc_code.toString().toLowerCase()];
          var eventCountry = iso2country[isoCode];
          return countryFilterState.value === eventCountry
        })
      }
      events_2.map((el, i) => {

        const eventMonthStart = utils.getMonthName(el.start_date_obj);
        const eventMonthEnd = utils.getMonthName(el.end_date_obj);
        const eventMonth = (eventMonthStart && eventMonthEnd && eventMonthStart === eventMonthEnd) ? eventMonthStart : eventMonthStart + ' ' + eventMonthEnd;

        this.addToMonthFilters(eventMonthStart);
        this.addToMonthFilters(eventMonthEnd);
      })
    }
    if(
        typeFilterState
    ){
      // country filtering
      let events_3 = events;
      if(genderFilterState){
        events_3 = events.filter((event)=>{
          return genderFilterState.value === event.gender
        })
      }

      // month filtering
      if(monthFilterState){
        events_3 = events.filter((event)=>{
          return monthFilterState.value === event.month || monthFilterState.value === event.end_month
        })
      }

      // country filtering
      if(countryFilterState){
        events_3 = events.filter((event)=>{
          var isoCode = ioc2iso[event.ioc_code.toString().toLowerCase()];
          var eventCountry = iso2country[isoCode];
          return countryFilterState.value === eventCountry
        })
      }
      events_3.map((el, i) => {
        const eventType = el.type.toString().toLowerCase();
        this.addToTypeFilters(eventType);
      })
    }
    if(
        genderFilterState
    ){
      // type filtering
      if(typeFilterState){
        events = events.filter((event)=>{
          return typeFilterState.value === event.type.toString().toLowerCase()
        })
      }

      // month filtering
      let events_4 = events;
      if(monthFilterState){
        events_4 = events.filter((event)=>{
          return monthFilterState.value === event.month || monthFilterState.value === event.end_month
        })
      }

      // country filtering
      if(countryFilterState){
        events_4 = events.filter((event)=>{
          var isoCode = ioc2iso[event.ioc_code.toString().toLowerCase()];
          var eventCountry = iso2country[isoCode];
          return countryFilterState.value === eventCountry
        })
      }
      events_4.map((el, i) => {
        this.addToGenderFilters(el.gender);
      })
    }


    // country filtering
    if(genderFilterState){
      events = events.filter((event)=>{
        return genderFilterState.value === event.gender
      })
    }

    // type filtering
    if(typeFilterState){
      events = events.filter((event)=>{
        return typeFilterState.value === event.type.toString().toLowerCase()
      })
    }

    // month filtering
    if(monthFilterState){
      events = events.filter((event)=>{
        return monthFilterState.value === event.month || monthFilterState.value === event.end_month
      })
    }

    // country filtering
    if(countryFilterState){
      events = events.filter((event)=>{
        var isoCode = ioc2iso[event.ioc_code.toString().toLowerCase()];
        var eventCountry = iso2country[isoCode];
        return countryFilterState.value === eventCountry
      })
    }

    // inject status
    events.forEach((event)=>{
      event.date_status = this.getEventStatus(event);
    })
    // var current_events = events.filter((event)=>{
    //   return event.date_status === 'CURRENT';
    // })
    // events = events.filter((event)=>{
    //   return event.date_status != 'CURRENT';
    // })
    //
    //
    // var current_events_el = this.buildEventArticle(current_events);
    var events_el = this.buildEventArticle(events);

    if(events.length === 0){
      events_el = <div className="col-sm-12 animated fadeIn message no-results"><p className="text-center">No results found for current filters</p></div>
    }

    return (
      <section className="event-listing">
        <header>
          <h1 className="text-center">{SportInfo.sectionName}</h1>
        </header>
        <YearNavMobile match={match} SportInfo={SportInfo}/>
        <YearNav match={match} SportInfo={SportInfo}/>
        <div className="page">
            { componentHasAlreadyFetchedOneTime && monthFilters.length > 0 &&
              //( countryFilters.length > 0 || monthFilters.length > 0 || typeFilters.length > 0 || genderFilters.length > 0) &&
            <EventCalendarFilters
              handleResetAll={(e)=>{this.handleResetAllFilters(e)}}
              handleChange={(e)=>{this.handleFiltersChange(e)}}
              handleCountryChange={(e)=>{this.handleCountryChange(e)}}
              handleMonthChange={(e)=>{this.handleMonthChange(e)}}
              handleTypeChange={(e)=>{this.handleTypeChange(e)}}
              handleGenderChange={(e)=>{this.handleGenderChange(e)}}
              countries={countryFilters}
              months={monthFilters}
              types={typeFilters}
              genders={genderFilters}
              SportInfo={SportInfo}
              match={match}
              filersState={this.props.filersState}

              />
            }
            <Scheduled title="Current" events={events} />
            <div className="page-inner">

            {/*<Equalizer nodes={() => this.getNodes()}>*/}
              <div ref="eventsWrapper" className="row">
                  {/*{current_events_el}*/}
                  {events_el}
              </div>
            {/*</Equalizer>*/}
          </div>
        </div>
      </section>
    );
  }
}

const EventListingRx = connect(mapStateToProps, mapDispatchToProps)(EventListing);

export default EventListingRx
