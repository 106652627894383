import React, { Component } from 'react';
import { connect } from "react-redux";
import appActions from '../redux/actions/appActions';

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'privacy-policy'}))},
  }
)

class PrivacyPolicyPage extends Component {
  componentDidMount(){
    this.props.setAppSection();
  }
  render() {
    return (
      <div className="container">
        <section className="privacy-policy">
          <header>
            <h1 class="text-center">Privacy Policy</h1>
          </header>
          <div className="page">
              <div className="row p-3 p-md-5">
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                      <h3 className="text-uppercase font-weight-normal text-primary">Privacy Policy</h3>
                      <p>
                        This privacy policy will explain how Longines Watch Co Françillon Ltd (“Longines”, “Longines Timing”, “we”, “us”, “our”) uses the personal data we collect from you when you use the website www.longinestiming.com. 
                      </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                    <h3 className="text-uppercase font-weight-normal text-primary">What data do we collect?</h3>
                    <p>                   
                      Longines Timing may collect the following data through analytical tools when you brows our webpage:
                    </p>
                    <p>
                      Metadata, for instance details of your visits to the Website, such as traffic data, location data, IP address, browser information, session data, preferences, settings, weblogs and other communication data, which we monitor during your interaction with the website.
                    </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                    <h3 className="text-uppercase font-weight-normal text-primary">How do we collect your data?</h3>
                    <p>                   
                      We collect data and process data when you use or view our website via your browser’s cookies and plug-in.
                    </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                    <h3 className="text-uppercase font-weight-normal text-primary">How will we use your data?</h3>
                    <p>                   
                      Our company collects your data so that we can:
                    </p>
                    <ul>
                      <li>
                        If you interact with us on third party social networks, then we process your data for such interaction with you on these networks (our interactions with you on a third party social network would be subject to that network’s privacy policies and terms of use).
                      </li>
                      <li>
                        We process metadata that we collect from you to improve our Website, analyze traffic pattern and Website usage, for targeted marketing, to develop and analyze statistics and demographics, or optimize our online presence and marketing efforts.
                      </li>
                      <li>
                        We process your personal data also to comply with and enforce applicable legal requirements, our Terms of Use, relevant industry standards, contractual obligations and our policies.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                    <h3 className="text-uppercase font-weight-normal text-primary">What are cookies</h3>
                    <p>                   
                      A cookie is a small piece of data sent from a website and stored on the user's computer or mobile device by the user's web browser while the user is browsing. Cookies were designed to be a reliable mechanism for websites to remember stateful information (such as items added in the shopping cart) or to record the user's browsing activity (including clicking particular buttons, logging in, or recording which pages were visited in the past). The lifespan of a cookie determines how long a cookie will remain on your computer before it deletes itself.
                    </p>
                    <p>                   
                      Browsers may accept or reject cookies automatically but allow you to change these settings. The help menu on most browsers will tell you how to change your browser setting as to cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies all together. You can also disable or delete cookies you have previously accepted if you wish to. To find out how to manage cookies on the following browsers, follow the provided link:
                    </p>
                    <ul>
                      <li>
                        <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%253DDesktop&hl=en" className="external-link" target="_blank">
                          Google Chrome
                        </a>
                      </li>
                      <li>
                        <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" className="external-link" target="_blank">
                        Microsoft Internet Explorer
                        </a>
                      </li>
                      <li>
                        <a href="https://support.apple.com/kb/ph19214?locale=en_US" classMame="external-link" target="_blank">
                        Apple Safari
                        </a>
                      </li>
                      <li>
                        <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" className="external-link" target="_blank">
                        Mozilla Firefox
                        </a>
                      </li>
                      <li>
                        <a href="http://www.opera.com/help/tutorials/security/privacy/" className="external-link" target="_blank">
                        Opera
                        </a>
                      </li>
                    </ul>
                    <p>
                    To further identify, manage and track activated cookies on this Website, you may install a cookie tracker, 
                    <a href="https://www.ghostery.com/" classname="external-link" target="_blank">for example the Ghostery add-on.</a>
                    </p>
                    <p>
                      On this Website, we may use the following cookies, cookie categories and other technologies, but no spyware, for the indicated purposes and, if applicable, deployed by or with the assistance of the indicated partners:
                    </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                    <h3 className="text-uppercase font-weight-normal text-primary">What types of cookies do we use?</h3>
                    <h4 className="font-weight-bold text-secondary h5">Strictly necessary cookies.</h4>
                    <p>                   
                      These are cookies that are required for the operation of the Website and for you to be able to complete services you ask for. They include, for example, cookies that enable you to log into secure areas of the Website.
                    </p>
                    <h4 className="font-weight-bold text-secondary h5">Functional cookies.</h4>
                    <p>                   
                      These cookies record information about choices you make on the Website, such as your user name, language or the region you are in. In this way, we are able to personalize your visit to the Website.
                    </p>
                    <h4 className="font-weight-bold text-secondary h5">Analytical cookies.</h4>
                    <p>                   
                      These cookies allow us to collect information about how visitors use the Website, for example to count visitors and to see how visitors move around the Website. They record your visit to the Website, the pages you have visited and the links you have followed. These cookies do not collect information that identifies you. All information is anonymous. This helps us to improve the way the Website works.
                    </p>
                    <ul>
                      <li>
                        <p>
                        We may rely on Google Analytics, a service provided by Google, Inc. (“Google”), to analyze your use of the Website. The information generated by Google Analytics is subject to Google’s privacy and cookie policy and will be transferred to and stored by Google on servers in the USA. 
                        Prior to the transfer, it will be anonymized by removing the last octet of your IP address. Google will then process it on our behalf in order to evaluate your use of the Website, to compile reports on Website activity and to provide other services related to Internet usage. Google will not link your IP address with any other data held by Google. 
                        Note, however, that if you have enabled Google to associate your web and app browsing history with your Google account, and you have given Google permission to use the information of your Google account to personalize ads, Google may link the analytical cookies, and the information collected with them, with the information from your Google account. 
                        You may refuse the use of cookies for the aforesaid purposes by setting your browser accordingly; however, in that case, you may not be able to use the full functionality of the Website. 
                        You may further opt-out of the collection of your usage data (including your IP address) and its processing by <a href="http://tools.google.com/dlpage/gaoptout?hl=en%2520in" className="external-link" target="_blank">downloading and installing the add-on</a> for your current web browser. 
                        For more information about the use of your data by Google, <a href="http://www.google.com/analytics/learn/privacy.html" className="external-link" target="_blank">please visit Google’s website</a>. The Google Analytics cookies used on this Website are:
                        </p>
                        <ul>
                          <li>
                            <p>
                            _ga: this cookie contains a unique value to distinguish between website visitors and has a lifespan of 2 years;
                            </p>
                          </li>
                          <li>
                            <p>
                            _gid: this cookie contains a unique value to distinguish between website visitors and has a lifespan of 24 hours;
                            </p>
                          </li>
                          <li>
                            <p>
                            _gat: this cookie is used to throttle requests to the server and has a lifespan of 10 minutes
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <h4 className="font-weight-bold text-secondary h5">Social media cookies.</h4>
                    <p>                   
                      These cookies allow you to share Website content with social media platforms (e.g., Facebook, Twitter, Instagram). We have no control over these cookies as they are set by the social media platforms themselves. Please have a look at section 3 below on pixels and social plug-ins to learn more about how social media cookies are used in association with social plug-ins.
                    </p>
                    <h4 className="font-weight-bold text-secondary h5">Which Pixel and Plug-in do we use and how can you opt-out?</h4>
                    <p>                   
                      We also use other technologies on our website which allow us and Third Party Providers to offer you a more personalized and engaged web experience, e.g. by offering you advertisements on your social media channels that are more relevant to you or by allowing you to share your experiences on our Website via social media. We therefore use the following technologies:
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>Social plugins.</strong> Social plug-ins are small pieces of software which create a link between your visit to our Website and the social media platform of a Third Party Provider. The social plug-in will let the Third Party Provider know that you have visited our Website and may let the Third Party Provider receive cookies he has placed on your computer beforehand. We use social plug-ins of the following Third Party Providers: 
                        </p>
                        <ul>
                          <li>
                            <a href="https://developers.facebook.com/docs/plugins" className="external-link" target="_blank">
                              Facebook
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      Please click on the URL’s next to each of these Third Party Providers to learn more about their plug-ins. Note that we have no control over how these social plug-ins work, the data that they collect or what the Third Party Provider does with this information. For more information on how these Third Party Providers use your personal data collected via their social plug-ins, you are kindly referred to their respective privacy policies.
                    </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                      <h3 className="text-uppercase font-weight-normal text-primary">How do we use cookies?</h3>
                      <p>
                        Our company uses cookies in a range of ways to improve your experience on our website, including:
                      </p>
                      <p>
                        Remembering your personal preferences;<br/>
                        Understanding how you use our website;<br/>
                        Social networks integration;
                      </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                      <h3 className="text-uppercase font-weight-normal text-primary">How long do we keep your personal data?</h3>
                      <p>
                      Your personal data are only processed for as long as needed to achieve the purposes which are described above or up until such time where you withdraw your consent for processing them, unless we or any other third party have an overriding interest in keeping your personal data identifiable or when there is a legal or regulatory obligation or a judicial or administrative order that prevents us from de-identifying. 
                      </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                      <h3 className="text-uppercase font-weight-normal text-primary">What we share with others?</h3>
                      <p>
                        We share information with third parties who help us provide our service, including:
                      </p>
                      <p>
                        Google Analytics, which allow us to analyze your use of our Website. 
                      </p>
                      <p>
                        We may further disclose your personal data to third party service providers acting on our behalf (processors), limited to the purpose of the execution of their obligations, which are contractually bound to adhere to an adequate level of data protection when processing your personal data.
                      </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                      <h3 className="text-uppercase font-weight-normal text-primary">What are your data protection rights?</h3>
                      <p>
                      You have the right:
                      </p>
                     <ul>
                      <li>
                        <p>
                        to request from us access to and rectification or deletion of your personal data;
                        </p>
                      </li>
                      <li>
                        <p>
                        to request us to restrict the processing of your personal data, in particular to object to the processing of your personal data for direct marketing purposes; and
                        </p>
                      </li>
                      <li>
                        <p>
                        to request from us to provide you or any person or entity you appointed with a digital file of your personal data (data portability).
                        </p>
                      </li>
                     </ul>
                     <p>
                     You may withdraw your consent that allows us to process your personal data for the indicated purposes at any time.
                      </p>
                      <p>
                      To exercise the above rights, you may contact us as indicated below.
                      </p>
                      <p>
                      You also have the right to lodge a complaint with the competent authority.
                      </p>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="txt-wrap">
                      <h3 className="text-uppercase font-weight-normal text-primary">How to contact us</h3>
                      <p>
                      If you have any questions about our privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
                      </p>
                      <p>
                      Email us at: <a href="mailto:support@longinestiming.com">support@longinestiming.com</a>
                      </p>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </div>
    );
  }
}

const PrivacyPoliciyPageRx = connect(null, mapDispatchToProps)(PrivacyPolicyPage);

export default PrivacyPoliciyPageRx
