import * as actionTypes from '../actionTypes/appActionTypes';

const setAppSection = payload => ({ type: actionTypes.SET_APP_SECTION, payload });
const appInLoading = payload => ({ type: actionTypes.APP_IN_LOADING, payload });
const saveFilters = payload => ({ type: actionTypes.APP_SAVE_FILTERS, payload });
const resetFilters = payload => ({ type: actionTypes.APP_RESET_FILTERS, payload });

export default {
  setAppSection,
  appInLoading,
  saveFilters,
  resetFilters
}
