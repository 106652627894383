import countryToISO from '../resources/country_to_iso2.json';
import iso2ioc from '../resources/iso2_to_ioc.json';

const utils = {
  sanitize: (str) => {
    if(str){
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";

      for (var i=0, l=from.length ; i<l ; i++){
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace('.', '-') // replace a dot by a dash
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
        .replace(/-+/g, '-'); // collapse dashes
    }
    return str;
  },

  sanitize_whitespace: (str) =>{
    if(typeof str === 'string'){
      str = str.replace(/ /g, '');
    }
    return str;
  },

  getMonthName: (date) =>{
    date = new Date(date);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[date.getMonth()];
  },

  strToDate(str){
    if(typeof str === 'string'){
    var parts = str.match(/(\d+)/g);
    return new Date(parts[2], parts[1]-1, parts[0]);
    } else {
      return null;
    }
  },

  eventDate(event){
    if(!event.start_date_obj){
      return;
    }

    if(!event.end_date_obj){
      event.end_date_obj = event.start_date_obj;
    }

    var str;
    var startMonth = this.getMonthName(event.start_date_obj);
    var endMonth = this.getMonthName(event.end_date_obj);
    var startDate = event.start_date_obj.getDate();
    var endDate = event.end_date_obj.getDate();
    var eventYear = event.start_date_obj.getFullYear();

    var termination = {
      1: 'st',
      2: 'nd',
      3: 'rd',
      21: 'st',
      22: 'nd',
      23: 'rd',
      31: 'st',
      other: 'th',
    }
    var startDateStr =  (termination[startDate]) ? startDate + termination[startDate] : startDate + termination['other'];
    var endDateStr = (termination[endDate]) ? endDate + termination[endDate] : endDate + termination['other'];

    if(startMonth === endMonth && startDate === endDate){
      str = startMonth + ' ' +startDateStr + ' ' + eventYear;
    }
    else if(startMonth === endMonth){
      str = startMonth + ' ' +startDateStr + ' - ' + endDateStr + ' ' + eventYear;
    } else {
      str = startMonth + ' ' + startDateStr + ' - ' + endMonth + ' ' + endDateStr + ' ' + eventYear;
    }
    return str;

  },

  utcDateStr(date){
    const format = (n) => (n > 9 ? "" + n: "0" + n);

    let d = format(date.getUTCDate());
    let m = format(date.getUTCMonth() + 1);
    let y = format(date.getUTCFullYear());

    return d + '.' + m + '.' + y ;
  },

  sortEvents(){

  },

  countryToISO(c){
    c = this.capitalizeWord(c);
    var iso = countryToISO[c];
    var ioc = iso2ioc[iso];
    return ioc ? ioc.toUpperCase() : c ;
  },

  typeAbreviation(t){
    const types = {
      // 'longines global champions tour' : 'LGCT',
      // 'longines FEI nations cup™ jumping': 'FEI Nations Cup™',
      // 'longines FEI world cup™': 'FEI World Cup™',
      // 'artistic gymnastics': 'artistic',
      // 'rhythmic gymnastics': 'rhythmic',

    }
    return types[t] || t ;
  },

  capitalizeWord(text){
    text = text.toLowerCase()
    var t = text.split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

    return t;
  },

  generateEventMeta(events, sport){

    var cpt = 1;
    events.forEach((event) =>{
      if(sport === 'alpine-skiing'){
        event.id = event.id = utils.sanitize(event.name + '-' + event.city ) + '-' + event.start_date.replace(/\./g,'') + cpt;
        // event.live_url = 'http://live.fis-ski.com/lval0079-longines.htm#/short';
      } else if (sport === 'equestrian') {
        let status = event.canceled ? '-canceled' : '';
        event.id = event.id = utils.sanitize(event.name + '-' + event.city ) + status;
      } else {
        event.id = utils.sanitize(event.name + '-' + event.city );
      }

      // ensure prop has data
      event.ioc_code = event.ioc_code || "";
      event.city = event.city || "";

      event.sport = sport;
      event.start_date_obj = utils.strToDate(event.start_date);
      event.end_date_obj = utils.strToDate(event.end_date || event.start_date);
      event.month = utils.getMonthName(event.start_date_obj);
      event.end_month = utils.getMonthName(event.end_date_obj);
      event.city = event.city.replace(/ $/,"");

      if(event.logo_url){
        event.logo_url = event.logo_url.replace('http:','https:');
      }

      cpt++;
    })

    //sort by start_date
    if (sport === "equestrian"){
      // chronologic sort
      events.sort(function(a, b) {
        return new Date(a.start_date_obj) - new Date(b.start_date_obj);
      });
    } else {
      // anti-chronologic sort
      events.sort(function(a, b) {
        return new Date(b.start_date_obj) - new Date(a.start_date_obj);
      });
    }
  }

}

export default utils
