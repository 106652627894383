import * as actionTypes from "../actionTypes/appActionTypes";

const initialState = {
    appSection: 'homepage',
    appInLoading : true,
    filters: {
      countryFilterState: null,
      monthFilterState: null,
      typeFilterState: null,
      genderFilterState: null,
    }
}
const AppReducer = (appState = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_APP_SECTION:
        let newState = Object.assign({}, appState, action.payload);
      return newState ;
    case actionTypes.APP_IN_LOADING:
        let newState2 = Object.assign({}, appState, {appInLoading : action.payload});
      return newState2 ;
    case actionTypes.APP_SAVE_FILTERS:
        let newState3 = Object.assign({}, appState, {filters : action.payload});
        sessionStorage.setItem('lti:filtersState', JSON.stringify(newState3.filters));
      return newState3 ;
    case actionTypes.APP_RESET_FILTERS:
        let newState4 = Object.assign({}, appState, {filters : {
          countryFilterState: null,
          monthFilterState: null,
          typeFilterState: null,
          genderFilterState: null,
        }});
        sessionStorage.setItem('lti:filtersState', JSON.stringify(newState4.filters));
      return newState4 ;

    default:
        return appState;
  }
};

export default AppReducer
