import * as actionTypes from '../actionTypes/gymnasticsActionTypes';
import utils from '../../helpers/utils';

const fetchGymnasticsListing = year => ((dispatch) => {
    const url = 'https://gym.longinestiming.com/json/' + year ;
    dispatch(fetchGymnasticsListingBegin(year));
    return fetch(url)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {

        // json.forEach((event) =>{
        //   event.id = utils.sanitize(event.name + '-' + event.city );
        //   event.sport = 'gymnastics';
        //   event.start_date_obj = utils.strToDate(event.start_date);
        //   event.end_date_obj = utils.strToDate(event.end_date);
        //   event.month = utils.getMonthName(event.start_date_obj);
        //   event.end_month = utils.getMonthName(event.end_date_obj);
        //   event.city = event.city.replace(/ $/,"");
        //
        //   if(event.logo_url){
        //     event.logo_url = event.logo_url.replace('http:','https:');
        //   }
        //
        // })
        //
        // //sort by start_date
        // json.sort(function(a,b){
        //   return new Date(b.start_date_obj) - new Date(a.start_date_obj);
        // })

        utils.generateEventMeta(json, 'gymnastics');

        const newListing = {};
        newListing[year] = json;
        dispatch(fetchGymnasticsListingSuccess(newListing));
        return newListing;
      })
      .catch(error => dispatch(fetchGymnasticsListingError(error)))
  }
)

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}


const fetchGymnasticsListingBegin = year => ({ type: actionTypes.FETCH_GYMNASTICS_LISTING_BEGIN, year });
const fetchGymnasticsListingSuccess = payload => ({ type: actionTypes.FETCH_GYMNASTICS_LISTING_SUCCESS, payload });
const fetchGymnasticsListingError = error => ({ type: actionTypes.FETCH_GYMNASTICS_LISTING_ERROR, error });

export default {
  fetchGymnasticsListing,
  fetchGymnasticsListingBegin,
  fetchGymnasticsListingSuccess,
  fetchGymnasticsListingError
}
