const urlRewriter = (oldUrl, props) => {

  const { events, fetchEquestrianListing } = props;

  oldUrl = oldUrl || '';

  //clean GET params
  oldUrl = oldUrl.split('?');
  oldUrl = oldUrl[0];

  if(!oldUrl.match(/#!\/equestrian/)){
    window.location.href = '/';
    return;
  }

  // clean the domain part of the URL
  oldUrl = oldUrl.split('#!/equestrian/');
  oldUrl = oldUrl[1];

  if(oldUrl){
    // check for lite/
    var litePath = '';
    if(oldUrl.match(/lite\//)){
      litePath = 'lite/';
      oldUrl = oldUrl.replace('lite/','');
    }

    var splittedUrl = oldUrl.split('/');

    var urlToFind = splittedUrl;
    urlToFind = urlToFind.slice(0,5);
    urlToFind = urlToFind.join('/');
    console.log(urlToFind);

    // year
    var year;
    if(year = urlToFind.match(/[0-9]{4}\//)){

      year = year[0].replace('/','');
      console.log(year);
      //fetchEquestrianListing(year);

      if(events[year] && events[year].length > 0){
        var event = events[year].filter((event)=>{
          if(event.event_url){
              var regex = new RegExp(urlToFind,"g");
              return event.event_url.match(regex);
          }
        });
        event = event[0];
        console.log(event);

        if(event){
          if(splittedUrl[splittedUrl.length] === 'index.html'){
            var redirect = '/equestrian/' + litePath + year + '/' + event.id;
            console.log('redirect to: '+redirect);
            // window.location.href = redirect;
          } else {
            var redirect = '/equestrian/' + litePath + year + '/' + event.id;
            var extension = oldUrl.replace(urlToFind, '');
            console.log('redirect to: '+redirect+extension);
            // window.location.href = redirect;
          }
          window.location.href = redirect+extension;
        }
      } else {
        fetchEquestrianListing(year);
      }
    }
  } else {
    var redirect = '/equestrian';
    window.location.href = redirect;
  }
}

export default urlRewriter
