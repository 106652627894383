import React, { Component } from 'react';
import { connect } from "react-redux";
import appActions from '../redux/actions/appActions';
import YouTube from 'react-youtube';
import image  from '../assets/imgs/longines-lepine.jpg';

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'sport-history'}))},
  }
)

const YouTubeOptions= {
  width: '100%',
  playerVars: {
        modestbranding: 1
      }
}

class SportHistoryPage extends Component {
  componentDidMount(){
    this.props.setAppSection();
  }
  render() {
    return (
      <div className="container">
        <section className="sport-history">
          <header>
            <h1 class="text-center">Longines Sport History</h1>
          </header>
          <div className="page">
              <div className="row">
                <div className="col-md-6">
                  <div className="img-wrap">
                    <img src={image} alt="Longines Sport History" className="img-fluid"/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="txt-wrap">
                    <h3>Long-lasting involvement</h3>
                      Longines’ long-lasting involvement in sports timekeeping started in 1878 with the first chronograph manufactured by the brand.
                      Building on its in-house expertise, Longines has gradually developed a special relationship with the world of sport. It can count now many years of experience as official timekeeper of world championships, and as partner of international sports federations.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="video-wrapper">
                    <YouTube
                      opts={YouTubeOptions}
                      videoId="fthDIiZj6Lk"
                    />
                  </div>
                </div>
              </div>
          </div>
        </section>
      </div>
    );
  }
}

const SportHistoryPageRx = connect(null, mapDispatchToProps)(SportHistoryPage);

export default SportHistoryPageRx
