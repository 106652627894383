import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import EventLogo from './EventLogo';
import ioc2iso from '../resources/ioc_to_iso2.json';
import utils from '../helpers/utils';

class EventResult extends Component {
  constructor(props){
    super(props);
    console.log('new EventResult')
  }

  getCurrentYearSeason(){
    const { SportInfo } = this.props;
    const currentYear = (new Date()).getFullYear();

    let currentYearSeason;
    if(SportInfo.slug === 'alpine-skiing'){
      currentYearSeason = (new Date()).getMonth() <= 9 ? currentYear : currentYear + 1;
    } else {
      currentYearSeason = currentYear;
    }
    return currentYearSeason;
  }

  render() {
    const { SportInfo, history,  event, event_data } = this.props;

    const currentYear = this.getCurrentYearSeason().toString();
    var yearSlug = history.location.pathname.match(/\/[0-9]{4}\//);
    yearSlug = yearSlug[0].replace('/', '').replace('/', '');

    var backToSectionSlug;
    if(currentYear === yearSlug){
      backToSectionSlug =  '/' + SportInfo.slug;
    } else {
      backToSectionSlug =  '/' + SportInfo.slug +'/'+ yearSlug;
    }


    if(event_data){
      $(this.refs.ajxWrapper).html(event_data);
      if(SportInfo.slug === 'equestrian'){
        $(this.refs.ajxWrapper).find('a').click((e)=>{
          if(!$(e.currentTarget).hasClass('dl-link') && !$(e.currentTarget).hasClass('external-link')){
            e.preventDefault();
            var $e = $(e.currentTarget);
            history.push($e.attr('href'));
          }
        })
      }
    }

    var flag;
    if(event.ioc_code){
    var isoCode = ioc2iso[event.ioc_code.toString().toLowerCase()];
      flag = 'fi-' + isoCode;
    }


    // remove table-responsive if not needed
    // cannot be in processors because it must in DOM to calculate width
    const $tables = $('.table-responsive');
    $tables.each((i, el)=>{
      const $el = $(el);
      const tWidth = $el.find('table').outerWidth();
      const wWidth = $(window).outerWidth();
      if(tWidth < wWidth){
        $el.removeClass('table-responsive');
      }
    })

    const eventDateStr = utils.eventDate(event);
    const iocCode = event.ioc_code ? event.ioc_code.toUpperCase(): '';

    const todayStr = utils.utcDateStr(new Date());

    return (
      <section className="event-detail">
        <header>
          <h1 className="text-center">{SportInfo.sectionName}</h1>
          <div className="back-to-section">
            <Link to={backToSectionSlug} ><span className="icon"><ion-icon name="arrow-back"></ion-icon></span> Back to section</Link>
          </div>

        </header>
        <article className="page">
          <div className="page-inner">
            <header className="event-header">
             <div className="row">
              <div className="col-8 title-wrapper">
                <h3 className="event-title">{event.name}</h3>
                <ul className="event-details list-unstyled">
                <li className="location">
                  <span className="icon" >
                    <ion-icon name="pin"></ion-icon>
                  </span>
                  <span className="city">{event.city}</span>, &nbsp;
                  <span className="ioc-code">{iocCode}</span>
                </li>
                <li className="start-date">
                  <span className="icon" >
                    <ion-icon name="time"></ion-icon>
                  </span>
                  {eventDateStr}
                </li>
                </ul>
                <div className="flag-overlay"><span className={"flag-icon " + flag }></span></div>
              </div>
              <div className="col-4 logo-wrapper">
                <EventLogo event={event} SportInfo={SportInfo} />
              </div>
             </div>
            </header>

            { (SportInfo.slug === 'alpine-skiing') && (todayStr === event.start_date) &&
              <div className="live-timing text-center" style={{'padding': '15px', 'paddingBottom': '30px'}}>
                <a href={event.live_url} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-square btn-big text-uppercase text-center">
                  Live timing
                </a>
              </div>
            }

            { (SportInfo.slug === 'alpine-skiing') && (utils.strToDate(todayStr) > utils.strToDate(event.start_date)) &&
              <div className="live-timing  text-center" style={{'padding': '15px', 'paddingBottom': '30px'}}>
                <a href={event.live_url} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-square btn-big text-uppercase text-center">
                  Timing info
                </a>
              </div>
            }

            <div ref="ajxWrapper" className="ajax-wrapper"></div>
          </div>

          <div className="modal fade" id="ajax-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                    </div>
                </div>
            </div>
        </div>
        </article>
      </section>
    );
  }
}

export default EventResult
