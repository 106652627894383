import * as actionTypes from "../actionTypes/alpineskiingActionTypes";

const initialState = []
const ItemsReducer = (itemsState = initialState, action) => {
  switch (action.type) {
    // case actionTypes.FETCH_EQUESTRIAN_LISTING:
    //   const year = action.payload.year;
    //   const newListing = action.payload.events
    //
    //   let newState = Object.assign({}, itemsState);
    //   newState[year] = newListing
    //   return newState ;

    case actionTypes.FETCH_ALPINESKIING_RANKING_SUCCESS:
      let newState2 = Object.assign({}, itemsState, action.payload);
      return newState2 ;

    // case actionTypes.DEL_ITEM:
    //     let i = action.payload;
    //     return  [ ...itemsState.slice(0, i), ...itemsState.slice(i + 1)];

    default:
        return itemsState;
  }
};

export default ItemsReducer
