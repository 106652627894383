import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";
// import alpineskiingEventsMock from './alpineSkiingEventsMock';
// import equestrianEventsMock from './equestrianEventsMock';
// import gymnasticsEventsMock from './gymnasticsEventsMock';

const currentYear = (new Date()).getYear() + 1900;
const initialState = {
  // events: {
  //   alpineskiing: alpineskiingEventsMock,
  //   equestrian: equestrianEventsMock,
  //   gymnastics: gymnasticsEventsMock,
  // },

  events: {
    alpineskiing: {[currentYear]:[]},
    equestrian: {[currentYear]:[]},
    gymnastics: {[currentYear]:[]},
  }
};



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer,initialState, composeEnhancers(applyMiddleware(thunk)));

// register the store globally for TEST
window.store = store;

export default store;
