import React, { Component } from 'react';
import { connect } from "react-redux";
import appActions from '../redux/actions/appActions';
import YouTube from 'react-youtube';
import image  from '../assets/imgs/longines-lepine.jpg';
import $ from "jquery";

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'sport-history'}))},
    appInLoading: (bool) => { dispatch(appActions.appInLoading(bool))},
  }
)

const YouTubeOptions= {
  width: '100%',
  playerVars: {
        modestbranding: 1
      }
}

class SportHistoryPage extends Component {
  constructor(props) {
    super(props);
    // Crée une référence pour stocker l’élément DOM textInput
    this.section = React.createRef();
  }
  componentDidMount() {
    // this.props.setAppSection();
    this.props.appInLoading(false);

    const fitHeight = () => {
      var targetHeight =
        $(window).height() -
        ($(".banner").outerHeight() + $(".footer").outerHeight() + 10);
      $(this.section.current).height(targetHeight);
      console.log(this.section, "targetHeight");
    }

    fitHeight();
    $(window).resize(fitHeight);
    
  }
  render() {
    return (
      <div className="container">
        <section className="d-flex" ref={this.section}>
          <header className="m-auto">
            <h1 className="text-center">404</h1>
            <h3 className="text-center pb-5">Sorry this page does not exist</h3>
            <div className="text-center">
              <a href="/" className="btn btn-outline-secondary btn-lg">
                Back to homepage
              </a>
            </div>
          </header>
        </section>
      </div>
    );
  }
}

const SportHistoryPageRx = connect(null, mapDispatchToProps)(SportHistoryPage);

export default SportHistoryPageRx
