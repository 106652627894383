import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import appActions from '../redux/actions/appActions';
import { connect } from "react-redux";

const mapDispatchToProps = dispatch => ({
    appInLoading: (bool) => { dispatch(appActions.appInLoading(bool))},
  }
)
class AppLink extends Component {
  setAppInLoadingState(){
    this.props.appInLoading(true);
  }
  render(){
    const { exact, to, className} = this.props;

    return(
      <NavLink exact={exact} to={to} className={className} onClick={()=>{this.setAppInLoadingState()}}>
        {this.props.children}
      </NavLink>
    );
  }
}

const AppLinkRx = connect(null, mapDispatchToProps)(AppLink);
export default AppLinkRx
