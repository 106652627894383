import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import EventLogo from './EventLogo';
import ioc2iso from '../resources/ioc_to_iso2.json';
import utils from '../helpers/utils';

import { AppLink } from './AppLink';
import { initial } from 'lodash';
class RgpdDisclaimer extends Component {
  constructor(props){
    super(props);

    const rgpdConfig = JSON.parse(localStorage.getItem('rgpd-config')) || { analytics: true };

    // if(rgpdConfig.configSaved){}
    this.state = {
      gaInitDone : false,
      isRgpdConfigSaved: rgpdConfig,
      iSConfigureModalOpen: false,
      config : {
        configSaved: rgpdConfig.configSaved,
        acceptAll : rgpdConfig.acceptAll,
        analytics : rgpdConfig.acceptAll ? true : rgpdConfig.analytics,
      }
    }
  }

  acceptAll(){
    this.setState({
      iSConfigureModalOpen : false,
      config : {
        configSaved: true,
        acceptAll: true,
        analytics : true,
      }
    });

    
    setTimeout(() => {
      this.save();
    }, 600);
  }

  rejectAll(){
    this.setState({
      iSConfigureModalOpen : false,
      config : {
        configSaved: true,
        acceptAll: false,
        analytics : false,
      }
    });

    setTimeout(() => {
      this.save();
    }, 600);
  }

  configure(){
    this.setState({
      iSConfigureModalOpen : true
    });
  }

  toggleAnalytics(){
    this.setState({
      config : {
        analytics: !this.state.config.analytics,
        acceptAll: this.state.config.acceptAll,
        configSaved: this.state.config.configSaved,
      }
    });
  }

  save(){
    
    this.setState({
      gaInitDone: true,
      iSConfigureModalOpen : false,
      config : {
        analytics: this.state.config.analytics,
        acceptAll: this.state.config.acceptAll,
        configSaved: true,
      }
    });

    setTimeout(() => {
      localStorage.setItem('rgpd-config', JSON.stringify(this.state.config));

      if((this.state.config.acceptAll || this.state.config.analytics) && !this.state.gaInitDone ){
        window.initGA();
      }
    }, 400);
  }

  render() {

    const { gaInitDone, iSConfigureModalOpen, config } = this.state;
    if(config.configSaved ){
      if((config.acceptAll || config.analytics) && !gaInitDone){
        this.setState({ gaInitDone: true })
        window.initGA();
      }
      return <></>;
    }

    return (
      <>
      { iSConfigureModalOpen &&
        <div className="rgpd-overlay">
        
          <div className="rgpd-modal">
            <h3 className="text-center text-primary mb-4">Configure my cookies</h3>
            <p className="text-center mb-3">On this Website, we may use the following cookies, cookie categories and other technologies, but no spyware, for the indicated purposes and, if applicable, deployed by or with the assistance of the indicated partners:</p>
            <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <span className="label-wrapper">Strictly necessary cookies</span>
                <label>
                  Required
                </label>
              </li>
              <li className="list-group-item">
              <span className="label-wrapper">Analytical cookies</span>
                <label className="switch">
                  <input type="checkbox" class="default" checked={config.analytics} onClick={()=>{this.toggleAnalytics()}} />
                <span className="slider round"></span>
                </label>
              </li>
            </ul>
            <div className="text-right mt-5">
              <span className='btn btn-primary btn-square mr-2' onClick={()=>{this.save()}}>Accept selected cookies</span>
            </div>
         </div>
        </div>
      }
      

      <div className="rgpd-disclaimer">
        <div className="rgpd-disclaimer__inner container">
          <div className="row align-items-center">
            <div className="rgpd-disclaimer__message col-lg-6">
              I agree that this Website uses cookies and similar technologies for providing me this Website and its functionalities, for obtaining insights about its use and for providing me relevant advertisements. 
              To find out more about cookies and how to manage them, please see our <Link className='text-light' to="/privacy-policy">privacy policy</Link>
            </div>
            <div className="rgpd-disclaimer__action col-lg-6">
              <span className='btn btn-sm btn-outline-light btn-square mr-2' onClick={()=>{this.acceptAll()}}>ACCEPT ALL COOKIES</span>
              <span className='btn btn-sm btn-outline-secondary btn-square mr-2' onClick={()=>{this.configure()}}>CONFIGURE</span>
              <span className='btn btn-sm btn-outline-secondary btn-square' onClick={()=>{this.rejectAll()}}>REJECT ALL COOKIES</span>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default RgpdDisclaimer
