import React from 'react';

import DH from '../assets/imgs/event-type/alpine-skiing/downhill.png';
import SL from '../assets/imgs/event-type/alpine-skiing/slalom.png';
import GS from '../assets/imgs/event-type/alpine-skiing/giant-slalom.png';
import SG from '../assets/imgs/event-type/alpine-skiing/super-g.png';
import AC from '../assets/imgs/event-type/alpine-skiing/alpine-combined.png';

import None from '../assets/imgs/event-type/equestrian/longines-event.png';
import Trampoline from '../assets/imgs/event-type/gymnastics/trampoline.png' ;

const AlpineSkiingTypeList = {
  DH,
  SL,
  GS,
  SG,
  AC
}

const EquestrianTypeList = {
  None,
}

const GymnasticsTypeList = {
  Trampoline,
}

const EventLogo = (props) => {
  const { SportInfo, event } = props;

  var EventTypeList;
  var logoPlaceholder;

  switch (SportInfo.slug) {
    case 'gymnastics':
      EventTypeList = GymnasticsTypeList;
      logoPlaceholder = Trampoline;
      break;

    case 'alpine-skiing':
      EventTypeList = AlpineSkiingTypeList;
      logoPlaceholder = DH;
      break;

    default:
      EventTypeList = EquestrianTypeList;
      logoPlaceholder = None;
      break;
  }

  logoPlaceholder = EventTypeList[event.type] || logoPlaceholder;

  if(event.logo_url && SportInfo.slug !== 'alpine-skiing'){
    return (
      <figure className='event-logo'>
          <img src={event.logo_url} alt="competition-type" className="img-fluid" />
      </figure>
    )
  } else {
    return (
      <figure className='event-logo event-logo-placeholder'>
          <img src={logoPlaceholder} alt="competition-type" className="img-fluid" />
      </figure>
    )
  }
}

export default EventLogo
