import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import SportNavigation from './SportNavigation';
import UserNavigation from './UserNavigation';
import AppLink from './AppLink';


class AppNavigation extends Component {
  render(){
    return (
      <nav className="navbar navbar-dark bg-faded">
          <button className="js-nav-toggle nav-toggle" type="button"><i></i></button>
          <SportNavigation className="sport-navigation nav navbar-nav flex-row"/>
          <AppLink to="/" className="navbar-nav navbar-brand">
            Longines Timing
          </AppLink>
          <UserNavigation className="user-navigation nav navbar-nav flex-row ml-md-auto" />
      </nav>
    );
  }
}
const AppNavigationDecorated = withRouter(props => <AppNavigation {...props}/>);
export default AppNavigationDecorated
