import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'
import MediaQuery from 'react-responsive';


var navItems;

class YearNav extends Component {
  constructor(props){
    super(props);

    const { match, SportInfo } = this.props;
    const url = match.url.replace('/'+match.params.year, '');
    const currentYear = this.getCurrentYearSeason();
    let navYears;

    switch (SportInfo.slug) {
      case 'alpine-skiing':
        navYears = [currentYear, currentYear -1, currentYear -2, currentYear - 3, currentYear - 4];
        navItems = navYears.map((year, i) => {
          var navLinkUrl =  url + '/'+ year;
          return (
            { value: navLinkUrl, label: 'Season ' + (year-1) +'/'+year }
          );
        });
        break;

      case 'equestrian':
        console.log(url)
        navYears = [];
        for (let i = currentYear; i >= 2012; i--) {
          navYears.push(i);       
        }

        if( (new Date()).getMonth() >= 11 ){
          navYears.unshift(currentYear + 1);
        }

        navItems = navYears.map((year, i) => {
          var navLinkUrl =  url + '/'+ year;
          return (
            { value: navLinkUrl, label: 'Season ' + year }
          );
        });
        break;
    
      default:
        navYears = [currentYear, currentYear -1, currentYear -2, currentYear - 3, currentYear - 4];
          navItems = navYears.map((year, i) => {
          var navLinkUrl =  url + '/'+ year;
          return (
            { value: navLinkUrl, label: 'Season ' + year }
          );
        });
        break;
    }

    var selectedYear =  (match.url.match(/\/[0-9]{4}/)) ? match.url.match(/\/[0-9]{4}/)[0].replace('/','') : currentYear ;
    var selectedNavItem;
    navItems.forEach((item)=>{
      var re = new RegExp(selectedYear,"g");
      if(item.value.match(re)){
        selectedNavItem = item;
      }
    })

    this.state = {
      currentNavItem: selectedNavItem,
    }
  }
  getCurrentYearSeason(){
    const { SportInfo } = this.props;
    const currentYear = (new Date()).getFullYear();

    let currentYearSeason;
    if(SportInfo.slug === 'alpine-skiing'){
      currentYearSeason = (new Date()).getMonth() < 9 ? currentYear : currentYear + 1;
    } else {
      currentYearSeason = currentYear;
    }
    return currentYearSeason;
  }
  handleChange(e){
    this.setState({
      currentNavItem: e,
    })
  }
  render() {
    const { match } = this.props;

    const currentYear = (new Date()).getYear() + 1900;
    var selectedYear =  (match.url.match(/\/[0-9]{4}/)) ? match.url.match(/\/[0-9]{4}/)[0].replace('/','') : currentYear ;
    var selectedNavItem;
    navItems.forEach((item)=>{
      var re = new RegExp(selectedYear,"g");
      if(item.value.match(re)){
        selectedNavItem = item;
      }
    })

    var mustRedirect = false;

    if(this.state.currentNavItem){
      if(match.url !== this.state.currentNavItem.value){
        mustRedirect = true;
      }
    }

    return (
          <MediaQuery query="(max-width: 991px)">
            <div id="mobile-nav">
              <Select classNamePrefix="filter" placeholder="SELECT A YEAR" defaultValue={selectedNavItem} value={selectedNavItem}  options={navItems} onChange={(e)=>{this.handleChange(e)}} />
                {  mustRedirect &&
                  <Redirect to={this.state.currentNavItem.value} />
                }
           </div>
         </MediaQuery>
    );
  }
}

export default YearNav
