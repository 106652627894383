import React, { Component } from 'react';
import YouTube from 'react-youtube';
import Poster from '../../assets/imgs/conquest-placeholder.jpg';

const YouTubeOptions= {
  width: '100%',
  playerVars: {
        modestbranding: 1,
        autoplay: 1,
        enablejsapi: 1,
        rel: 0,
        showinfo: 1,
      }
}

class DatetimePicker extends Component {
  // static defaultProps = {
  //  timeInputHidden: true
  // }
  constructor(props){
    super(props);
    this.state = {
      timeInputHidden: true
    };
  }
  toggleTime(){
    this.setState({
      timeInputHidden: !this.state.timeInputHidden
    })
  }
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }
  render() {
    const { timeInputHidden } = this.state;

    // scheduled
    const today = new Date();
    const scheduleDate = new Date('2020-01-10T14:00:00'); //new Date('1/10/2020');
    const isActive = (today > scheduleDate);

    return (
      <div>
        { isActive &&
          <div className="cta conquest-cta">
            <div className="conquest-cta-inner">
              <div className="row align-items-center">
                <div className="box-content col-md-6">
                <div className="txt-wrap p-4 pr-5">
                  <h3 className="mb-3" >With the Conquest V.H.P., Longines offers the sports lovers a watch combining ultraprecision, high technicality and a sporty look, marked by the brand’s unique elegance.</h3>
                  <a target="_blank" href="https://www.longines.com/conquest-vhp" className="btn btn-primary btn-lg btn-square text-uppercase">Discover now</a>
                </div>
                </div>
                <div className="box-content col-md-6">
                  <div className="p-md-3">
                    <div className="poster" onClick={()=>{ this.toggleTime() }}>
                      { !timeInputHidden &&
                        <div className="video-wrapper">
                          <div class="embed-responsive embed-responsive-16by9">
                          <YouTube
                            opts={YouTubeOptions}
                            videoId="AP_5vxibewA"
                            onReady={(e)=>{this._onReady(e)}}
                            className="embed-responsive"
                          />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

    );
  }
}

export default DatetimePicker
