import { combineReducers } from 'redux';
import equestrian from './equestrianReducer';
import alpineskiing from './alpineskiingReducer';
import alpineskiingRanking from './alpineskiingRankingReducers';
import gymnastics from './gymnasticsReducer';
import app from './appReducer';

const eventReducer = combineReducers({
  alpineskiing,
  equestrian,
  gymnastics,
})

const rankingReducer = combineReducers({
  alpineskiing : alpineskiingRanking
})

export default combineReducers({
  events: eventReducer,
  ranking: rankingReducer,
  app
})
