import React from "react";
import AppLink from './AppLink';

const SportNavigation = ({className}) => (
  <ul className={className}>
    <li className="nav-item">
      <AppLink to="/alpine-skiing" className="nav-link">
        Alpine Skiing
      </AppLink>
    </li>
    <li className="nav-item">
      <AppLink to="/equestrian" className="nav-link">
        Equestrian
      </AppLink>
    </li>
    <li className="nav-item">
      <AppLink to="/gymnastics" className="nav-link">
        Gymnastics
      </AppLink>
    </li>
  </ul>
);

export default SportNavigation
