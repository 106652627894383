import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import $ from 'jquery';
import EventResult from '../components/EventResult';
import actions from '../redux/actions/alpineskiingActions';
import ioc2iso from '../resources/ioc_to_iso2.json';
import alpineTypes from '../resources/alpineTypes.json';
import appConfig from '../appConfig';

import { renderToString } from 'react-dom/server'

// Equestrian dep
import dataProcessor from '../dataProcessors/alpineskiingProcessor';

// ENVIRONNEMENT
let env;
if(appConfig.env === 'preprod'){
  env = 'preprod';
} else {
  env = window.location.href.match(/https:\/\//) ? 'https' : 'production';
}
const environnement = env;


const mapStateToProps = state => ({
    events: state.events.alpineskiing,
    ranking: state.ranking.alpineskiing,

  }
)
const mapDispatchToProps = dispatch => ({
    fetchRanking: (year, discipline, gender, type) => { dispatch(actions.fetchAlpineskiingRanking(year, discipline, gender, type))}
  }
)

const SportInfo = {
  sectionName : 'Alpine Skiing',
  slug: 'alpine-skiing'
}

const availableRankingDiscipline = ['DH', 'SL', 'GS', 'AC'];

class EventResultCr extends Component {
  // static defaultProps = {
  //   ranking: []
  // }

  constructor(props){
    super(props);
    console.log('new EventResultCr');
    this.state = {
      loading: true,
      event_data: null,
    };
  }

  getEventNameFormUrl(url){
    var name;
    name = decodeURI(url.replace(/-/g,' '));
    return name;

  }

  fetchRankingFromProvider(){
    console.log('fetchRankingFromProvider');
    const event = this.getTargetEvent();

    const { match } = this.props;
    const year = match.params.year || (new Date()).getFullYear();


    const discipline = (availableRankingDiscipline.indexOf(event.type) > -1) ? event.type : 'ALL';
    if(discipline != 'ALL'){
      this.props.fetchRanking(year, discipline, event.gender, 'WC');
    }
    this.props.fetchRanking(year, 'ALL', event.gender, 'WC');
    this.props.fetchRanking(year, 'ALL', event.gender, 'RSS');
  }

  loadProviderData(event){
    if(this.isRankingReady() && !event.event_url){
      return;
    }

    // console.log(rankingsEl, 'rankingsEl');
    const { match, ranking } = this.props;
    const { year, eventId } = match.params;
    const reactUrlForEvent = '/alpine-skiing/' + year + '/' + eventId;

    if(!this.isRankingReady()){
      this.fetchRankingFromProvider();
    }
    
    const wcRankingCollectionId = year +'-'+'ALL' +'-'+event.gender +'-'+'WC';
    const discipline = (availableRankingDiscipline.indexOf(event.type) > -1) ? event.type : 'ALL';
    const disciplineRankingCollectionId = year +'-'+discipline +'-'+event.gender +'-'+'WC';
    const rssRankingCollectionId = year +'-'+'ALL'+'-'+event.gender +'-'+'RSS';
    // WC Ranking
    var wcRankingEl;
    wcRankingEl = this.buildRankings(wcRankingCollectionId);
    wcRankingEl = wcRankingEl ? renderToString(wcRankingEl) : null;
    // RSS Ranking
    var rssRankingEl;
    rssRankingEl = this.buildRankings(rssRankingCollectionId);
    rssRankingEl = rssRankingEl ? renderToString(rssRankingEl) : null;
    // Discipline Ranking
    var disciplineRankingEl;
    if(disciplineRankingCollectionId != wcRankingCollectionId){
      disciplineRankingEl = this.buildRankings(disciplineRankingCollectionId);
      disciplineRankingEl = disciplineRankingEl ? renderToString(disciplineRankingEl) : null;
    }

    var ranking_data = {
      general: {
        tab_title: 'WORLD CUP STANDING',
        ranking: wcRankingEl,
      },
      discipline: {
        tab_title: alpineTypes[event.type.toLowerCase()] +' CUP STANDING',
        ranking: disciplineRankingEl,
      },
      rss: {
        tab_title: 'RISING STARS STANDING',
        ranking: rssRankingEl,
      },
    }

    if(match.path === '/alpine-skiing/:year/:eventId'){

      let urlToDownload = event.event_url.replace('http:', 'https:');
      urlToDownload = urlToDownload + '&env='+environnement;

      $.get({
        url: urlToDownload,
        cache: false,
      })
      .done((data)=>{
       data = dataProcessor.processData(data, event, reactUrlForEvent, ranking_data);
       this.setState({
         loading : false,
         event_data: data
       });
      })
      .fail(()=>{
        console.log('Error while fetching data');
      });

    }
  }

  componentDidMount(){
    // only work in redux events of the year has been fetch before mounting
    if(this.isRankingReady()){
      this.loadProviderData(this.getTargetEvent());
    } else{
      this.fetchRankingFromProvider();
    }
    window.scrollTo(0,0)
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if( match.url !== prevProps.match.url){
      this.loadProviderData(this.getTargetEvent());
    }
    setTimeout(() => {
      if(!this.isRankingReady()){
        this.fetchRankingFromProvider();
      }
    }, 2000);
  }

  getTargetEvent(){
    const { match, events } = this.props;
    const year = match.params.year;
    const eventListing = events[year] || [];
    var event = eventListing.find(({ id }) => id === match.params.eventId) || {};
    return event;
  }

  buildRankings(discipline){
    const { history, ranking } = this.props;
    var rankings;
    if(ranking[discipline]){
      if(ranking[discipline].length > 0){
        rankings = ranking[discipline].map((rank, i)=>{
          var flag;
          if(rank.athlete.country.code){
            var isoCode = rank.athlete.country.code.toString().toLowerCase();
            flag = 'fi-' + isoCode;
          }
          return (
            <tr key={i}>
              <td className="rank">{rank.rank}</td>
              <td></td>
              <td>
                <span className={"flag-icon " + flag } />
                <a href="#">
                  <span className="text-uppercase">{rank.athlete['last-name']}</span> {rank.athlete['first-name']}
                </a>
              </td>
              <td className="wc-points">{rank.result}</td>
            </tr>
          )
        })
        rankings = <div className="rankings">
          <table className="table table-results table-rankings">
            <thead>
              <tr>
                <th>Rank</th>
                <th></th>
                <th>Name</th>
                <th className="wc-points"><span className="text-xs-right">Points</span></th>
              </tr>
            </thead>
            {rankings}
          </table>
        </div>;
      }
    }
    return rankings;
  }
  isRankingReady(){
    const { history, match, ranking } = this.props;
    const { year, eventId } = match.params;
    const event = this.getTargetEvent();
    const wcRankingCollectionId = year +'-'+'ALL' +'-'+event.gender +'-'+'WC';
    const discipline = (availableRankingDiscipline.indexOf(event.type) > -1) ? event.type : 'ALL';
    const disciplineRankingCollectionId = year +'-'+discipline +'-'+event.gender +'-'+'WC';

    // console.log(!!(ranking[wcRankingCollectionId] && ranking[disciplineRankingCollectionId]), 'isRankingReady - ranking');

    if(ranking[wcRankingCollectionId] && ranking[disciplineRankingCollectionId]){
      return true;
    } else{
      return false;
    }
  }

  render() {
    const { history, match, ranking } = this.props;
    const { event_data } = this.state;
    const { year, eventId } = match.params;
    const event = this.getTargetEvent();

    // console.log(this.isRankingReady(), 'this.isRankingReady()');
    // console.log(event_data, 'event_data');
    if(this.isRankingReady() && !event_data){
      // reload if this component has been mounted before the redux store mapping
      this.loadProviderData(event);
    }

    // const wcRankingCollectionId = year +'-'+'ALL' +'-'+event.gender +'-'+'WC';
    // const discipline = (availableRankingDiscipline.indexOf(event.type) > -1) ? event.type : 'ALL';
    // const disciplineRankingCollectionId = year +'-'+discipline +'-'+event.gender +'-'+'WC';
    // if(!event_data || !ranking[wcRankingCollectionId] || !ranking[disciplineRankingCollectionId]){
    //   this.loadProviderData(event);
    //   console.log(wcRankingCollectionId, 'wcRankingCollectionId');
    //   console.log(disciplineRankingCollectionId, 'disciplineRankingCollectionId');
    //   console.log(ranking, 'ranking');
    // }

    window.ga('send', 'pageview', match.url);
    
    return (
      <EventResult SportInfo={SportInfo} history={history} event={event} event_data={event_data} />
    );
  }
}

const EventResultDecorated = withRouter(props => <EventResultCr {...props}/>);
const EventResultDecoratedRx = connect(mapStateToProps, mapDispatchToProps)(EventResultDecorated);

export default EventResultDecoratedRx
